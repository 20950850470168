import axios from 'axios'
const url = process.env.VUE_APP_API_PATH + 'v1/clients';
const resume_url = process.env.VUE_APP_API_PATH + 'v1/sailing-resumes';
const qualification_url = process.env.VUE_APP_API_PATH + 'v1/qualification';
const experience_url = process.env.VUE_APP_API_PATH + 'v1/experiences';
const crew_url = process.env.VUE_APP_API_PATH + 'v1/crew-members';
import _ from "lodash";
export default {
    state: {
        client: null,
        clients: null,
        client_list: null,
        sailing_resume: null,
        sailing_resume_changed: false,
        sailing_questions: null,
        crew_abilities: null
    },
    getters: {
        clients: state => state.clients,
        client: state => state.client,
        sailing_resume: state => state.sailing_resume,
        qualifcations: state => state.sailing_resume?.qualifcations ?? [],
        experiences: state => state.sailing_resume?.experiences ?? [],
        documents: state => state.sailing_resume?.documents ?? [],
        sailing_questions: state => state.sailing_questions,
        crew_abilities: state => state.crew_abilities,
        sailing_resume_changed: state => state.sailing_resume_changed,
    },
    mutations: {

        SET_CLIENT(state, client) {
            if(!client) {
                state.client = null;
                state.sailing_resume = null;
            }
            if(state.client) {
                state.client = {...state.client,...client}
            } else {
                state.client = client;
            }
        },

        SET_SAILING_RESUME(state, data) {
            if(state.sailing_resume) {
                state.sailing_resume = {...state.sailing_resume,...data}
            } else {
                state.sailing_resume = data;
            }
        },
        SET_SAILING_RESUME_DETAILS(state, data) {
            for (const [key, value] of Object.entries(data)) {
                state.sailing_resume[key] = value
            }
        },
        SET_SAILING_RESUME_DOCUMENTS(state, documents) {
            state.sailing_resume.documents = documents;
        },
        SET_EXPERIENCE(state, data) {
            if(state.sailing_resume?.experiences && state.sailing_resume.experiences.length) {
                state.sailing_resume.experiences = state.sailing_resume.experiences.map( it => {
                    if(it.hash === data.hash || (data.id && data.id === it.id)) {
                        it = {... data};
                    }
                    return it;
                })
            } else {
                state.sailing_resume.experiences = [];
                state.sailing_resume.experiences.push(data);
            }
        },
        SET_CLIENTS(state, clients) {
            state.clients = clients;
        },
        SET_CLIENT_LIST(state, clients) {
            state.client_list = clients;
        },

        SET_SAILING_RESUME_LICENCE(state, value) {
            state.sailing_resume.sailing_licence = +value;
        },
        SET_SAILING_RESUME_BOAT_OWNER(state, value) {
            state.sailing_resume.boat_owner = +value;
        },
        SET_SAILING_QUESTIONS(state, data) {
            state.sailing_questions = data;
        },
        SET_CREW_ABILITIES(state, data) {
            state.crew_abilities = data;
        },
        SET_SAILING_RESUME_CHANGED(state, data) {
            state.sailing_resume_changed = data;
        },

    },
    actions: {
        fetchAllClients({commit,dispatch},data) {
            return new Promise((resolve, reject) => {
                axios.get(url,{params:{...data}}).then(res => {
                if(res.data && typeof res.data === 'object') {
                    commit('SET_CLIENT_LIST',res.data)
                }
                resolve(res.data);
            }).catch(err => {
                commit('SET_ERROR',err.response.data);
                reject(err)
            });
        })
        },
        fetchClients({commit,dispatch},search) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_API_PATH + 'v1/search/?class=client&field=name&search='+search) .then(res => {
                    const clients = [];
                    if(typeof res.data === 'object') {
                        res.data.forEach(it => {
                            if(it['name']) {
                                clients.push(it['name']);
                            }
                        })
                    }
                    commit('SET_CLIENTS',clients)
                    resolve();
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        fetchClient({commit,dispatch},id) {
            return new Promise((resolve, reject) => {
                axios.get(url + '/' + id).then(res => {
                    if(res.data && typeof res.data === 'object') {
                        commit('SET_CLIENT',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            });
        },
        deleteClient({commit},id) {
            return new Promise((resolve, reject) => {
                if(!id) {
                    reject("no id provided");
                }
                axios.delete(url + '/' + id) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        deleteBoat({commit}, {client_id, boat_id}) {
            return new Promise((resolve, reject) => {
                if(!client_id || !boat_id) {
                    reject("id is not provided");
                }
                axios.delete(url + `/${client_id}/delete-boat/${boat_id}`) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },

        resetPassword({commit,dispatch},data) {
            return new Promise((resolve, reject) => {
                if(!data || !Object.keys(data).length) {
                    commit('SET_ERROR','No data to send');
                    reject(err);
                }
                axios.post(process.env.VUE_APP_API_PATH + 'v1/clients/'+ data.client_id +'/reset-password', data.form).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            });
        },
        storeClient({commit,dispatch},{form, files}) {
            return new Promise((resolve, reject) => {
                if(!form || !Object.keys(form).length) {
                    commit('SET_ERROR','No form data to send');
                    reject(err);
                }

                const formdata = new FormData();
                formdata.append("data",JSON.stringify(form));
                if(files && files.length) {
                    for( let i = 0; i < files.length; i++ ){
                        let file = files[i].file;
                        let type = files[i].type;
                        if(type === 'Avatar') {
                            formdata.append(`${type}`,file,file.name);
                        } else {
                            formdata.append(`${type}[]`,file,file.name);
                        }
                    }
                }
                let newurl = url;
                if(form.id) {
                    formdata.append("_method", "PUT");
                    newurl = newurl + "/"+ form.id;
                }
                axios.post(newurl, formdata,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    if(res.data && typeof res.data === 'object') {
                        commit('SET_CLIENT',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            });
        },

        deleteDocumentMedia({commit},{docId,mediaId}) {
            return new Promise((resolve, reject) => {
                axios.delete(url + `/delete-media/${docId}/${mediaId}`) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        deleteAvatar({commit},{clientId,mediaId}) {
            return new Promise((resolve, reject) => {
                axios.delete(url + `/delete-avatar/${clientId}/${mediaId}`) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        deleteDocument({commit},id) {
            return new Promise((resolve, reject) => {
                axios.delete(url + `/delete-document/${id}`) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        fetchSailingResume({commit,dispatch},id) {
            return new Promise((resolve, reject) => {
                axios.get(resume_url + '/client/' + id).then(res => {
                    if(res.data) {
                        commit('SET_SAILING_RESUME',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    //commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            });
        },
        storeSailingResume({commit,dispatch},{form, files, no_commit}) {
            return new Promise((resolve, reject) => {
                if(!form || !Object.keys(form).length) {
                    commit('SET_ERROR','No form data to send');
                    reject(err);
                }

                const formdata = new FormData();
                formdata.append("data",JSON.stringify(form));
                if(files && files.length) {
                    for( let i = 0; i < files.length; i++ ){
                        let file = files[i].file;
                        let type = files[i].type;
                        if(type === 'Avatar') {
                            formdata.append(`${type}`,file,file.name);
                        } else {
                            formdata.append(`${type}[]`,file,file.name);
                        }
                    }
                }
                let newurl = resume_url;
                if(form.id) {
                    formdata.append("_method", "PUT");
                    newurl = newurl + "/"+ form.id;
                }

                axios.post(newurl, formdata,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                    if(res.data && typeof res.data === 'object' && !no_commit) {
                        commit('SET_SAILING_RESUME',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            });
        },
        deleteExperience({commit},id) {
            return new Promise((resolve, reject) => {
                axios.delete(experience_url +`/${id}`) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        storeExperience({commit},data) {
            return new Promise((resolve, reject) => {
                let method = 'post';
                let url = experience_url;
                if (data.id) {
                    method = 'put';
                    url = url + '/' + data.id;
                }
                    axios[method](url, data).then(res => {
                        resolve(res.data);
                    }).catch(err => {
                        commit('SET_ERROR', err.response.data);
                        reject(err)
                    });
            })

        },
        storeExperienceList({commit},data) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_PATH + 'v1/experiences/save-list';
                    axios.post(url,data).then(res => {
                        resolve(res.data);
                    }).catch(err => {
                        commit('SET_ERROR', err.response.data);
                        reject(err)
                    });
            })

        },
        storeDocumentsList({commit},{ data, resume_id }) {
            return new Promise((resolve, reject) => {
                let url = resume_url + `/${resume_id}/save-documents-list`;
                    axios.post(url,data).then(res => {
                        resolve(res.data);
                    }).catch(err => {
                        commit('SET_ERROR', err.response.data);
                        reject(err)
                    });
            })
        },

        storeSailingResumeDocuments({commit,dispatch},{resume_id,form, files, no_commit}) {
            return new Promise((resolve, reject) => {
                if(!form || !Object.keys(form).length) {
                    commit('SET_ERROR','No form data to send');
                    reject(err);
                }

                const formdata = new FormData();
                formdata.append("data",JSON.stringify(form));
                if(files && files.length) {
                    for( let i = 0; i < files.length; i++ ){
                        let file = files[i].file;
                        let type = files[i].type;
                        if(type === 'Avatar') {
                            formdata.append(`${type}`,file,file.name);
                        } else {
                            formdata.append(`${type}[]`,file,file.name);
                        }
                    }
                }

                let url = resume_url + `/${resume_id}/save-documents-list`;
                axios.post(url, formdata,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                    if(res.data && typeof res.data === 'object' && !no_commit) {
                        commit('SET_SAILING_RESUME_DOCUMENTS',res.data)
                    }
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            });
        },
        storeClientBoats({commit},{ form, client_id }) {
            return new Promise((resolve, reject) => {
                let action = url + `/${client_id}/save-boats-list`;
                axios.post(action,form).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR', err.response.data);
                    reject(err)
                });
            })
        },
        deleteCrewMember({commit},id) {
            return new Promise((resolve, reject) => {
                axios.delete(crew_url + `/${id}`) .then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            })
        },
        storeCrewMembers({commit},{ form, order_id }) {
            return new Promise((resolve, reject) => {
                let action = crew_url + `/${order_id}/save-list`;
                axios.post(action,form).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR', err.response.data);
                    reject(err)
                });
            })
        },
        storeCrewMember({commit},form) {
            return new Promise((resolve, reject) => {
                let method = form.id ? 'put' : 'post';
                let url =  form.id ? crew_url + `/${form.id}` : crew_url;
                axios[method](url,form).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR', err.response.data);
                    reject(err)
                });
            })
        },
        fetchSailingQuestions({commit,dispatch},id) {
            return new Promise((resolve, reject) => {
                axios.get(qualification_url + '/questions').then(res => {
                    if(res && res.data) {
                        commit('SET_SAILING_QUESTIONS',res.data)
                        resolve(res.data);
                    }
                }).catch(err => {
                    //commit('SET_ERROR',err.response.data);
                    reject(err)
                });
            });
        },
        fetchCrewAbilities({commit,dispatch},id) {
            return new Promise((resolve, reject) => {
                axios.get(resume_url + '/crew-abilities').then(res => {
                    if(res && res.data) {
                        commit('SET_CREW_ABILITIES',res.data)
                        resolve(res.data);
                    }
                }).catch(err => {
                    reject(err)
                });
            });
        },
        sendCrewList({commit},order_id) {
            return new Promise((resolve, reject) => {
                let action = crew_url + `/${order_id}/send-crew-list`;
                axios.post(action).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR', err.response.data);
                    reject(err)
                });
            })
        },
        storeQualification({commit},{resume_id,data}) {
            return new Promise((resolve, reject) => {
                let url = process.env.VUE_APP_API_PATH + 'v1/qualification/'+ resume_id +'/save-qualification';
                axios.post(url,data).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    commit('SET_ERROR', err.response.data);
                    reject(err)
                });
            })

        },
    }
}
