import axios from "axios";

export default {
    state: {
        info: {
            loaded: false,
            locale: "en-US",
            bill: null,
        },
        currencies: {},
        all_countries: null,
        cities: null,
    },
    mutations: {
        SET_INFO(state, info) {
            state.info = info;
            state.info.loaded = true;
        },
        SET_CURRENCIES(state, currencies) {
            state.currencies = currencies;
        },
        CLEAR_INFO(state) {
            state.info = {};
        },
        SET_ALL_COUNTRIES(state, items) {
            state.all_countries = items;
        },
        SET_CITIES(state, items) {
            state.cities = items;
        },
    },
    actions: {
        fetchInfo({ commit, state }) {
            axios
                .get(process.env.VUE_APP_API_PATH + "v1/getinfo")
                .then((res) => {
                    if (res) {
                        commit("SET_INFO", res.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async fetchCur({ dispatch, commit }) {
            const oldapi =
                "https://api.exchangeratesapi.io/latest?symbols=USD,RUB,GBP,CAD";
            const api =
                "https://api.fastforex.io/fetch-multi?api_key=e8a00754c0-32d3dee012-qqxh12&from=USD&to=EUR,RUB,GBP,CAD";
            try {
                let response = await fetch(api);
                const res = await response.json();
                console.log(res);
                let currencies = null;
                if (res && res.results) {
                    const baseCur = res.base;
                    currencies = {
                        base: "USD",
                        rates: { ...res.results, [baseCur]: 1 },
                        date: res?.updated,
                    };
                    localStorage.setItem(
                        "currencies",
                        JSON.stringify(currencies),
                    );
                }
                commit("SET_CURRENCIES", currencies);
                return currencies;
            } catch (e) {
                commit("SET_ERROR", e);
                console.log(e);
            }
        },

        async fetchAllCountries({ commit }) {
            const api = "https://countriesnow.space/api/v0.1/countries/capital";
            try {
                let response = await fetch(api);
                const res = await response.json();
                if (res && res.error === false) {
                    commit("SET_ALL_COUNTRIES", res.data);
                    return res.data;
                }
            } catch (e) {
                commit("SET_ERROR", e);
                console.log(e);
            }
        },

        async fetchCities({ commit }, country) {
            const api = "https://countriesnow.space/api/v0.1/countries/cities";
            try {
                const response = await fetch(api, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    //mode: 'cors', // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    //credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *client
                    body: JSON.stringify({ country }), // body data type must match "Content-Type" header
                });
                const res = await response.json();
                console.log(res);
                if (res && res.error === false) {
                    commit("SET_CITIES", res.data);
                    return res.data;
                }
            } catch (e) {
                commit("SET_ERROR", e);
                console.log(e);
            }
        },
    },
    getters: {
        info: (state) => state.info,
        currencies: (state) => state.currencies,
        locale: (state) => state.info.locale || "en-US",
        all_countries: (state) => state.all_countries,
        cities: (state) => state.cities,
    },
};
